<template>
  <div class="w-full">
    <div
      class="model flex items-center justify-center w-full cursor-pointer"
      :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
      @click="openUpload()"
    >
        <icon
          :data="icons.img"
          height="30"
          width="30"
          class="cursor-pointer"
          original
        />
      <div
          class="ml-2 text-center"
      >
        {{ placeholder }}
      </div>
    </div>

  </div>
</template>

<script>
import img from '../../../assets/icons/attach.svg'

export default {
  name: 'Index',
  components: {
  },

  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '45px'
    },
    border: {
      type: String,
      default: '1px dashed #CCCCCC'
    },
    radius: {
      type: String,
      default: '5px'
    },
    background: {
      type: String,
      default: 'rgba(245, 245, 245, 0.5)'
    }
  },

  data () {
    return {
      icons: {
        img
      },
      activeDetail: false,
      selectedItem: null,
      file: null,
      url: null,
      scale: 100,
      quality: 30,
      originalSize: true,
      compressed: null,
      placeholder: null
    }
  },

  computed: {

  },

  mounted () {
    if (this.value !== null) {
      this.placeholder = this.value
    }
  },

  methods: {
    openUpload () {
      // const compressor = this.$refs.compressor.$el
      // compressor.click()
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        this.placeholder = e.target.files[0].name
        this.$emit('info', this.file)
      }
      input.click()
    },

    getFiles (obj) {
      // console.log(obj)
      this.img = obj.compressed.blob
      this.compressed = obj.compressed

      if (obj.compressed.size !== '0 kB') {
        // console.log('ok')
        const tab = []
        tab.push(obj.compressed.file)
        this.file = tab
        this.placeholder = tab[0].name
        // console.log(this.file)
        this.$emit('info', this.file)
      } else {
        this.quality = Math.floor(Math.random() * 40)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";

.model {
  color: #8E8EA9;
}

.button{
  background-color: #EFEFEF;
  height: 30px;
  border-radius: 3px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #5138EE;
}
.model:focus-within {
  border: 1px solid $focus-color;
}
.model:hover {
  border: 1px solid $hover-color;
}

</style>
