<template>
  <div class="w-full h-full flex items-center">
      <div class="w-auto">
        <div class="mb-4 text-c16">{{ allSteep[index].toUpperCase() }}</div>
        <icon
            :data="icons.valid"
            height="30"
            width="30"
            class="cursor-pointer"
            original
            v-if="position > index"
        />
        <icon
            :data="icons.invalid"
            height="30"
            width="30"
            class="cursor-pointer"
            original
            v-if="position <= index"
        />
      </div>
      <div class="w-full mr-4 ml-4" v-if="allSteep.length - 1 > index">
        <divider height="1px" color="#DDDDDD" v-if="position <= index" class="mt-10"/>
        <divider height="1px" color="#2B9E7C"  v-if="position > index" class="mt-10"/>
      </div>
  </div>
</template>

<script>
import divider from './divider'
import valid from '../../../assets/icons/validate.svg'
import invalid from '../../../assets/icons/invalid.svg'


export default {
  name: 'index',
  components: {
    divider
  },

  props: {
    allSteep: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        valid,
        invalid
      }
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.svg-fill{
  fill: transparent;
}
</style>
