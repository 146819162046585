<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center cursor-pointer">
      <div class="w-1/4">
          {{ montant.toLocaleString() }}
      </div>

      <div>X</div>

      <inputo class="w-1/3 ml-4" type="number" border="none" radius="0px" height="28px" background="#F5F5F5" @info="retourValue"/>
      <div class="ml-2 mr-2">=</div>
      <div class="w-1/3 bg-f5 h-28 flex items-center pl-3 pr-3">
        <div>{{ (montant * value).toLocaleString() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import inputo from "../../helper/form/input"

export default {
  name: "Index",
  components: {
    inputo
  },
  props: {
    montant: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      value: 0
    }
  },

  computed: {
    amount: function () {
      return this.montant * this.value
    }
  },

  methods: {
    retourValue (answer) {
      if (answer !== '' && answer !== null) {
        this.value = parseInt(answer)
      } else {
        this.value = 0
      }

      const obj = {
        amount: this.montant,
        count: this.value
      }
      this.$emit('info', obj)
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
</style>
